.input-radio__item {
    position: relative;

    input[type='radio'] {
        @extend .visually-hidden;

        + label {
            display: flex;
            align-items: center;
            cursor: pointer;

            &:before {
                @extend .mr-2xs;
                content: '';
                display: inline-block;
                background-size: map-get($icon-scale, md);
                width: map-get($icon-scale, md);
                height: map-get($icon-scale, md);
                background-image: url(../../asset/radio-unchecked.svg);
                position: relative;
                top: -1px;
            }
        }

        &:checked {
            + label:before {
                background-image: url(../../asset/radio-checked.svg);
            }
        }

        &:hover {
            + label {
                text-decoration: underline;
            }
        }

        @include keyboard-focus {
            @extend :hover;
        }
    }
}

.input-radio {
    &__item {
        + .input-radio__item {
            @extend .mt-xs;
        }

        + .input-radio__item--space-above {
            @include apply-utility(mt, md);
        }
    }
}
