html {
    @extend .font-sans;
    @extend .text-md;
    -webkit-font-smoothing: antialiased;
}

body {
    @extend .leading-tight;
}

p {
    @extend .leading-normal;
    margin-top: 0;
    margin-bottom: 0.8em;

    &:last-child {
        @extend .mb-0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    @extend .leading-tight;
    @extend .first-last-self-reset;
    @extend .font-slab;
    margin-top: 1em;
    margin-bottom: 0.25em;
}

h1,
.h1 {
    @extend .text-2xl;
}

h2,
.h2 {
    @extend .text-xl;
}

h3,
.h3 {
    @extend .text-lg;
}

h4,
.h4 {
    @extend .text-md;
}

h5,
.h5 {
    @extend .text-sm;
}

h6,
.h6 {
    @extend .text-xs;
}

li {
    @extend .leading-tight;
    margin-bottom: 0.65em;
}

blockquote {
    p {
        @extend .text-lg;
        font-style: italic;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    cite {
        @extend .text-sm;
    }
}

cite {
    font-style: normal;
}

small {
    @extend .text-sm;
}

a {
    @extend .color-link;
    // @extend .focus;

    &:hover {
        @extend .color-dark-1;
        text-decoration: none;
    }
}

figcaption {
    @extend .text-sm;
    @extend .mt-2xs;
    @extend .leading-tight;
}
