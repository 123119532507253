// Color
$black: #000000;
$gray-dark: #333333;
$gray: #AEAEAE;
$gray-light: #f1f1f1;
$white: #ffffff;
$orange: #F06100;
$orange-lighten: #fdd39a;
$yellow: #eebe2c;
$tan: #F5DECB;
$red: #F13944;
$maroon: #AB2A60;
$green: #94C01F;
$violet: #5F1DA7;
$light-green: #DEFAD1;
$dark-blue: #002889;
$bg-gradient-top: $dark-blue;
$bg-gradient-dark: linear-gradient(165deg, $bg-gradient-top 400px, $maroon 1000px, $orange 2500px);
$bg-gradient-dark-aurora: radial-gradient(circle at 0% 40%, #032365 10%, transparent 50%), radial-gradient(circle at 100% -20%, #FFF 10%, transparent 70%), radial-gradient(circle at 100% 100%, yellow 10%, transparent 40%), linear-gradient(120deg, #032365 40%, red 60%, yellow 90%);

// Abstracted colors
$color-primary: $orange;
$color-primary-lighten: $orange-lighten;
$color-secondary: $yellow;
$color-dark: $gray-dark;
$color-link: $black;
$color-text: $gray-dark;
$color-text-dark: $black;
$color-alert: $red;
$color-border: $gray;

// Utility
$text-color: (
    'dark-1': $black,
    'dark-2': $gray-dark,
    'light-1': $white,
    'link': $color-link,
);

$bg-color: (
    'dark-1': $bg-gradient-top,
    'dark-2': $gray-dark,
    'light-1': $white,
    'light-2': $gray-light,
    'light-3': $orange-lighten,
);

$bg-gradients: (
    'dark-1': $bg-gradient-dark,
    'dark-2': $gray-dark,
    'light-1': $white,
    'light-2': $gray-light,
    'light-3': $orange-lighten,
);
