.site-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 30px 20px;
  max-height: 85px;
  position: relative;
  z-index: 4;
  transition: background-color 200ms ease-in;

  &__menu {
    cursor: pointer;
  }

  &.menu-open {
    background-color: transparent;
  }

  &__symbol {
    flex-shrink: 0;
    width: 50px;
    @extend .mr-xs;
  }

  &__hamburger {
    overflow: visible;

    path {
      fill: $white;
      transition: transform 200ms ease, opacity 200ms ease-in-out;
    }

    #crosL,
    #crosR {
      transform-origin: center;
      opacity: 0;
    }

    #lineT,
    #lineM,
    #lineB {
      opacity: 1;
      transform-origin: center;
    }

    .menu-open & {
      #crosL,
      #crosR {
        transform: rotate(360deg);
        opacity: 1;
      }
      #lineT {
        transform: rotate(-540deg);
        opacity: 0;
      }
      #lineB {
        transform: rotate(540deg);
        opacity: 0;
      }
      #lineM {
        transform: rotateY(90deg);
        opacity: 0;
      }
    }
  }

  &__desc {
    @extend .text-lg;

    @include media-query(max-md) {
      @include apply-utility(text, sm);
    }
  }


  @include media-query(max-md) {
    background-color: $dark-blue;

    &__text {
      width: 60px;
    }
  }

  @include media-query(md) {
    padding-top: 50px;
    justify-content: center;
    margin-bottom: 120px;

    &__menu {
      display: none;
    }

    &__symbol {
      width: 70px;
    }
  }

}

@include media-query(max-md) {
  .top-hr {
    display: none;
  }
}

.top-hr {
  grid-area: hr;
  border: 1px solid rgba($gray-dark, .1);
  height: 1px;
  width: 100%;
}

.page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba($gray-dark, .1);

  @include media-query(max-md) {
    display: none;
  }

  > div {
    flex-basis: 33%;
    min-width: 260px;
  }

  .search-form {
    margin: 0;
  }
}