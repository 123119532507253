.program-areas {
  d\isplay: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  margin: 0 auto;

  @include media-query(md) {
    max-width: 50vw;
    margin: unset;
  }
}

.program-area {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  padding: 0;
  border: unset;
  border-radius: 20px;
  box-shadow: 1px 1px 3px 2px rgba(0,0,0,.1);
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.button {
    &--Studiekompetanse {
      background-color: $yellow;
    }

    &--Yrkeskompetanse {
      background-color: $green;
    }
  }

  &--type-icon {
    border-right: 1px solid $gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 0 4px;

    span {
      font-size: 10px;
      font-weight: 700;
      margin-top: 7px;
    }
  }

  &--labels {
    flex-basis: calc(100% - 30px);
    background: white;
    padding: 15px 20px;
    text-align: left;
    min-height: 108px;

    .program-name {
      color: $gray-dark;
      font-weight: 500;
      font-size: 20px;
      border-bottom: 1px solid rgba($gray-dark, .15);
      padding-bottom: 8px;
    }

    .program-section {
      color: $gray;
      font-size: 12px;
      margin-top: 5px;
      text-transform: uppercase;
    }
  }

  &--icon-after {
    flex-basis: 30px;
    margin: 0;
    padding: 0;
    display: block;
  }

  &:hover {
    box-shadow: 1px 1px 5px 4px rgba(0,0,0,.1);

    .program-area--icon-after {
      animation: swing 200ms ease;
      animation-iteration-count: 1;
    }
  }
}

@keyframes swing {
  15% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
