.modal {

    .info-modal {
      padding: 16px;
    }

    .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 10px;

        .icon--close {
            path {
                stroke: $dark-blue;
            }
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 7;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background: rgba($black, 0.1);

    @include media-query(max-md) {
        &>div {
            width: 100%;
        }
    }

    &.full-width-modal {
        background: $bg-gradient-dark;
    }
}

.info-modal {
    padding: 30px;
    background-color: white;
    border-radius: 16px;
    margin: auto;
    box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.55);
}