.input-checkbox {
    position: relative;

    input[type='checkbox'] {
        @extend .visually-hidden;

        + label {
            display: flex;
            align-items: center;
            cursor: pointer;

            &:before {
                @extend .mr-2xs;
                content: '';
                display: inline-block;
                background-size: map-get($icon-scale, md);
                width: map-get($icon-scale, md);
                height: map-get($icon-scale, md);
                background-image: url(../../asset/check-box-blank.svg);
                position: relative;
                top: -1px;
                flex-shrink: 0;
            }
        }

        &:checked {
            + label:before {
                background-image: url(../../asset/check-box.svg);
            }
        }

        &:hover {
            + label {
                text-decoration: underline;
            }
        }

        @include keyboard-focus {
            @extend :hover;
        }
    }

    &--has-child-checked {
        input[type='checkbox'] {
            + label:before {
                background-image: url(../../asset/check-box-indeterminate.svg);
            }
        }
    }
}
