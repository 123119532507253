.site-menu {
  @include media-query(md) {
    background: $bg-gradient-dark;
    min-height: 780px;
    border-radius: 20px;
    filter: drop-shadow(0px 33px 74px rgba(45, 51, 91, 0.15)) drop-shadow(0px 7.37098px 16.5508px rgba(45, 51, 91, 0.0894161)) drop-shadow(0px 2.19453px 4.30285px rgba(45, 51, 91, 0.0605839));
  }

}

.main-menu {

  @include media-query(max-md) {
    position: fixed;
    transition: top 200ms ease;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(130deg, #032366 10%, #bd0243 100%);
    top: -150vh;
    padding: 140px 30px 100px;
    z-index: 3;

    &.open {
      top: 0;
    }
  }

  .menu-links {
    ul {
      margin-top: 40px;
      padding: 0 10px;

      li {
        list-style: none;
      }

      li a,
      a span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $white;
        text-decoration: none;
      }

      li a {
        span {
          font-size: 22px;
        }

        svg {
          margin-right: 15px;
        }

        &:hover {
          svg {
            animation: swing 200ms ease-in-out;
            animation-iteration-count: 1;
          }
        }
      }

      li:not(:last-child) {
        border-bottom: 1px solid rgba($white, 0.2);

        @include media-query(max-md) {
          padding-bottom: 24px;
          margin-bottom: 24px;
        }
      }

    }

    h3 {
      margin: 0;
      font-size: 22px;
      font-weight: 400;
    }

    @keyframes swing {
      15% {
        transform: translateX(4px);
      }
      50% {
        transform: translateX(2px);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  @include media-query(md) {
    .profile-component,
    .search-form {
      display: none;
    }

    .menu-links {
      ul {
        padding: 0;
      }

      li {
        margin: 0;
        position: relative;

        a {
          padding: 20px 20px 24px;

          &:hover {
            background-color: rgba($orange, .2);
          }
        }

        a.active::before,
        a:hover::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 100%;
          left: 0;
          top: 0;
          background-color: $orange;
          border-radius: 0 10px 10px 0;
        }

        a:hover::before {
          background-color: adjust-hue($orange, -10deg);
        }
      }

      h3 {
        font-size: 20px;
      }

      .menu-right-icon {
        display: none;
      }
    }
  }
}