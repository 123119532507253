.kalender {
  margin-top: 30px;

  .page-title {
    margin-bottom: 20px;

    @include media-query(md) {
      svg path {
        stroke: $black;
      }
    }
  }

  .page-header {
    @include media-query(max-md) {
      color: $white;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      margin: 0;
    }

    .header-action {
      @include media-query(md) {
        display: grid;
        grid-template-columns: 2fr 1fr;
        margin-bottom: 45px;
      }

      p {
        max-width: 300px;
      }
    }
  }

  .calendar-action {
    @include media-query(max-md) {
      margin-top: 30px;
    }

    button {
      width: 100%;
      height: 50px;
      border-radius: 30px;
      justify-content: center;

      span {
        font-size: 18px;
        color: $white;
        font-weight: 400;

        @include media-query(md) {
          color: $gray-dark;
        }
      }

      svg {
        margin-right: 15px;
      }

      &:hover {
        span {
          color: $black;
        }
      }
    }
  }

  #icsDownloadLink {
    display: none;
  }
}

.events {
  display: grid;
  grid-gap: 15px;
  margin-top: 30px;
}

.event {
  background-color: $white;
  padding: 15px 15px 20px;
  border-radius: 16px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.03), 0px 7px 24px rgba(83, 83, 83, 0.19);


  @include media-query(md) {
    max-width: 50vw;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .event-details {
    font-size: 18px;

    a {
      text-decoration-color: $orange;
      text-decoration-thickness: 2px;
    }
  }

  .event-date {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid darken($gray-light, 10);

    .deadline {
      background-color: $light-green;
      border-radius: 6px;
      padding: 5px 10px;
      margin-right: 10px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }

    .daysto {
      font-size: 16px;
      color: #6E757C;
    }
  }
}
