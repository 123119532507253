.icon {
    &.icon {
        &--md {
            width: map-get($icon-scale, md);
            height: map-get($icon-scale, md);
        }
        &--sm {
            width: map-get($icon-scale, sm);
            height: map-get($icon-scale, sm);
        }
        &--fill-blue {
            path {
                fill: $dark-blue;
            }
        }
        &--fill-red {
            path {
                fill: $red;
            }
        }
    }
}
