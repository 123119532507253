.search-form {
  position: relative;
  margin-top: 30px;

  form {
    position: relative;
  }

  &__input {
    width: 100%;
    border: 2px solid $orange;
    padding: 16px 47px 16px 18px;
    border-radius: 30px;
    font-size: 18px;
  }

  .button--search {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}