.link-block,
.block {
    display: block;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.nowrap {
    white-space: nowrap;
}

// Resets
.first-last-self-reset {
    &:first-child {
        @extend .mt-0;
    }
    &:last-child {
        @extend .mb-0;
    }
}

.first-last-child-reset {
    > :first-child {
        @extend .mt-0;
    }
    > :last-child {
        @extend .mb-0;
    }
}

.list-reset {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.list-inline {
    > li {
        display: inline-block;
        margin-bottom: 0;
    }
}

.btn-reset {
    border: none;
    background: none;
    padding: 0;
}

// Visibility
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;

    &.focusable {
        &:active,
        &:focus {
            position: static;
            width: auto;
            height: auto;
            margin: 0;
            clip: auto;
            overflow: visible;
        }
    }
}

.element-invisible {
    @extend .visually-hidden;
}

// Float
.clearfix {
    &::after {
        content: ' ';
        display: block;
        clear: both;
    }
}

.text-line-through {
    text-decoration: line-through;
}
