.profile-component {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  color: $white;

  h1 {
    font-size: 24px;
    margin: 0;
  }

  .avatar {
    img {
      border-radius: 50%;
      width: 72px;
    }
  }

  .main-menu & {
    @include media-query(md) {
      display: none;
    }
  }

  .layout__main & {
    @include media-query(max-md) {
      display: none;
    }
  }

  @include media-query(md) {
    h1,
    button {
      color: $black;
    }
  }
}

.profile-page {
  @include media-query(max-md) {
    width: 100vw;
    margin-left: -30px;
    margin-bottom: -30px;
    min-height: calc(100vh - 85px);
    background-color: #f7f7f7;
  }

  @include media-query(md) {
    max-width: 50vw;
  }

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-bottom: 20px;

    svg {
      bottom: 45%;
      width: 100%;
      height: auto;
      position: absolute;
      z-index: -1;
    }

    @include media-query(md) {
      svg {
        display: none;
      }
    }

    @include media-query(max-md) {
      .user-info {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      img {
        margin-top: 25px;
        max-width: 160px;
        width: 30vw;
      }
    }

    h1 {
      font-size: 30px;
      font-weight: 500;
    }

    span {
      text-decoration: underline;
      text-decoration-color: $orange;
      text-decoration-thickness: 2px;
      color: $gray-dark;

      &:hover {
        cursor: pointer;
        text-decoration-color: $dark-blue;
      }
    }

    * {
      margin-bottom: 9px;
    }


    @include media-query(md) {
      flex-direction: row;
      margin-top: 35px;
      margin-bottom: 35px;
      gap: 20px;

      img {
        max-width: 88px;
      }
    }
  }

  @include media-query(max-md) {
    nav {
      width: 100vw;
    }
  }

  nav {
    display: flex;
    justify-content: space-between;
    grid-gap: 30px;
    margin-bottom: 40px;
    padding: 0 30px;

    button {
      background-color: white;
      border: 0;
      padding: 14px 10px;
      width: 50%;
      box-sizing: border-box;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: $gray-dark;
      font-size: 18px;
      font-weight: 700;

      svg {
        margin-right: 10px;

        &.icon--bookmark,
        &.icon--bookmarked {
          path {
            fill: $dark-blue !important;
          }
        }

        &.icon--heart path {
          fill: transparent !important;
          stroke: $dark-blue;
        }
      }

      &.active {
        border: 1px solid #f07c14;

        svg.icon--heart path {
          fill: $dark-blue !important;
          stroke: $dark-blue;
        }
      }
    }

  }

  main {
    padding: 0 30px 30px;

    .sort-lines {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      &--line {
        position: relative;

        &:hover .arrange {
          .up, .down {
            color: rgba($gray-dark, 0.4);
          }
        }

        .arrange {
          content: "";
          position: absolute;
          left: -20px;
          top: 10px;
          font-size: 24px;
          line-height: 1;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          gap: 3px;

          .up,
          .down {
            background-color: rgba($gray-dark, 0.2);
            color: rgba($gray-dark, 0.2);
            padding: 0 2px 6px;

            &:hover {
              color: rgba($gray-dark, 0.8);
              background-color: rgba($gray-dark, 0.3);
            }
          }

          .up {
            border-radius: 6px 6px 0 0;
          }

          .down {
            border-radius: 0 0 6px 6px;
          }
        }
      }
    }
  }
}

.edit-profile {
  padding: 30px;
  background-color: white;
  border-radius: 16px;
  margin: auto;
  box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.55);


  @include media-query(md) {
    width: 450px !important;
  }

  h3 {
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .avatars {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 3%;
  }

  svg {
    cursor: pointer;
    height: 100px;
    width: 100px;
    flex-basis: 30%;
    margin-bottom: 3%;

    &:hover {
      fill: none;
      stroke-width: 2;
      stroke: #f07c14;
    }
  }

  .active {
    fill: none;
    stroke-width: 2;
    stroke: #f07c14;
  }
}


.saved-lines {
  border-radius: 16px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.03), 0px 7px 25px rgba(232, 232, 232, 0.19);

  .header {
    display: grid;
    grid-template-columns: 1fr 14fr 1fr;
    align-items: center;
    background-color: $dark-blue;
    padding: 18px 20px;
    color: $white;

    .number {
      color: $orange;
      font-size: 18px;
      font-weight: 700;
    }

    .line-title {
      font-size: 18px;
      font-weight: 700;
    }

    button {
      padding: 0;

      svg {
        margin: 0;
        width: 14px;
      }
    }
  }

  .body {
    background-color: $white;
    padding: 0 16px;

    .line-step {
      display: flex;
      grid-gap: 16px;
      padding: 16px 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid rgba($gray-dark, .15);
      }

      .vg-stage {
        font-size: 13px;
        color: rgba($gray-dark, .7);
      }

      .vg-details {
        .vg-title {
          color: $gray-dark;
          margin-bottom: 10px;
        }

        .vg-school {
          font-size: 13px;
          color: rgba($gray-dark, .7);
        }
      }

    }

    .certification-type {
      font-size: 13px;
      color: rgba($gray-dark, .7);
      padding: 0 0 20px 20px;
    }
  }
}

.user-avatar {
  background: $white;
  border-radius: 50%;
}