ul.orange-bullets {
  padding: 0 20px;
  list-style: none;

  li {
    &:before {
      content: "\2022";
      color: $orange;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }

    &:hover::before {
      color: $orange-lighten;
    }
  }
}

:root {
  --border-bottom: 40px;
}

.dashed-before-list {
  &:before {
    content: '';
    position: absolute;
    top: 24px;
    left: 3px;
    border-left: 2px dashed $dark-blue;
    height: calc(100% - var(--border-bottom));
}
}