.articles-list {
  margin-top: 30px;
  min-height: 500px;

  .profile {
    color: $white;

    h3 {
      font-weight: 400;
      margin-top: 10px;
    }
  }

  @include media-query(max-lg) {
    grid-column-gap: 30px;

  }

  @include media-query(md) {
    display: grid;
    grid-template-rows: 120px 50px 100px auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
          "search profile"
          "hr hr"
          "title filter"
          "articles articles";

    .profile {
      grid-area: title;
      * {
        color: $black;
      }

      svg path {
        stroke: $black;
      }
    }

    .search-form {
      grid-area: search;
    }

    .profile-component {
      grid-area: profile;
    }

    .articles-filter {
      grid-area: filter;
    }

    .articles {
      grid-area: articles;
    }
  }

  @include media-query(lg) {
    grid-template-areas:
      "search empty profile"
      "hr hr hr"
      "title space filter"
      "articles articles articles";

  }
}

.articles {
  display: grid;
  grid-gap: 20px;
  margin-top: 30px;

  @include media-query(md) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

.articles-filter {
  position: relative;
  width: 100%;
  height: 50px;
  margin-top: 20px;

  ul {
    list-style: none;
    padding: 0;
    font-size: 18px;
    position: absolute;
    margin: 0;
    z-index: 2;
    width: 100%;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.25));
    background: linear-gradient(135deg, #000784 50%, #3c0c63 90%);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden;
    transform: rotateX(90deg);
    transform-origin: top;
  }

  ul,
  .article-filter-chevron {
    transition: transform 200ms ease-in;
  }

  &.open {
    ul {
      transform: rotateX(0);
    }

    .article-filter-chevron {
      transform: rotateX(180deg);
    }
  }

  li,
  .selected-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin: 0;
    color: $white;
    cursor: pointer;

    &:hover {
      background-color: rgba($dark-blue, 0.5);
    }

    &+li {
      border-top: 1px solid rgba($white, 0.5);
    }
  }


  .selected-filter {
    border-bottom: 1px solid $orange;
  }

  @include media-query(md) {
    ul {
      background: $white;
    }

    li,
    .selected-filter {
      color: $black;

      svg path {
        stroke: $black;
      }

      &+li {
        border-top: 1px solid rgba($black, 0.5);
      }


      &:hover {
        background-color: rgba($orange, 0.06);
      }
    }
  }
}

.article {
  background-color: $white;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.03), 0px 11px 24px rgba(227, 227, 227, 0.29);
  position: relative;

  .article-header {
    position: relative;
    border-bottom: 1px solid rgba($gray-dark, 0.15);

    .slide-to-content {
      position: absolute;
      left: 50%;
      bottom: 20px;
      transform: scale(2) rotate(90deg) translateX(-50%);
      cursor: pointer;

      path {
        stroke: $orange;
      }
    }

    @include media-query(max-md) {
      height: calc(100vh - 80px);
      text-align: center;
    }
  }


  @include media-query(md) {
    .slide-to-content {
      display: none;
    }
  }

  &--bookmark {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    &:hover {
      filter: hue-rotate(45deg) drop-shadow(1px 1px 1px rgba($black, .5));
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 500;
    margin: 16px 0;
  }

  &__excerpt {
    font-size: 18px;
    margin: 0;
    padding-bottom: 30px;
  }

  &--details {
    padding: 16px;
    text-align: center;

    h2 {
      margin-top: 10px;
      margin-bottom: 16px;
      font-size: 22px;
      font-weight: 600;
    }

    p {
      @extend .font-sans;
      font-size: 18px;
    }


    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        text-decoration-color: $orange;
      }
    }
  }

  .category {
    @extend .font-sans;
    background-color: $tan;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
  }

  &.single-article {
    padding-left: 15px;
    padding-right: 15px;

    @include media-query(md) {
      max-width: 50vw;
      margin: 0 auto;
    }

    .category {
      margin-top: 20px;
    }

    .body-section {
      padding: 30px 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid rgba($gray-dark, 0.15);
      }

      .button {
        margin-top: 16px;
        color: $black;
        text-decoration: none;

        &:hover {
          color: $orange;
          text-decoration: underline;
          text-decoration-color: $orange;
          text-decoration-thickness: 2px;
        }
      }

      h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        @extend .font-sans;
      }

    }

    u,
    details summary,
    .modal-handler {
      background:
              linear-gradient(to right, $orange, $orange),
              linear-gradient(to right, $orange, $maroon, $bg-gradient-top);
      background-size: 100% 2px, 0 2px;
      background-position: 100% 100%, 0 100%;
      background-repeat: no-repeat;
      transition: background-size 200ms;

      &:hover,
      &:focus {
        text-decoration-color: $orange-lighten;
        background-size: 0 2px, 100% 2px;
      }
    }

    ul {
      @extend .orange-bullets;
    }

    button {
      margin-top: 10px;
    }
  }

  details {
    display: inline-block;
    summary {
      cursor: pointer;
      list-style: none;

      &::-webkit-details-marker {
        display: none;
      }
    }

    &[open] p {
      position: fixed;
      top: 50%;
      left: 50%;
      right: 50px;
      transform: translate(-50%, -50%);
      padding: 20px;
      max-width: 500px;
      background-color: #FFF;
      box-shadow: 0 0 0 100vh rgb(0 0 0 / 60%);
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
    }

    svg {
      flex-basis: 20px;
      cursor: pointer;

      &:hover path {
        stroke: $orange;
      }
    }

    .details-modal-body {
      flex-basis: calc(100% - 30px);

      h3 {
        margin-bottom: 10px;
      }
    }
  }

}

@include media-query(max-md) {
  .single-article-container {
    margin-top: -80px;
  }
}

.load-more,
.to-the-top {
  margin-top: 24px;
  height: 50px;
  display: flex;
  justify-content: center;

  .button {
    transition: all 350ms ease-in;
    height: 47px;
  }

  &.is-not-sticky,
  &.is-sticky {
    button {
      position: fixed;
      bottom: 30px;
      width: calc(100% - 60px);
    }
  }

  &.is-not-sticky {
   .button {
     bottom: -100%;
   }
  }

  &.is-sticky {
    .button {
      background-color: rgba(0,0,0,0.4);
      border-color: rgba(0,0,0,0.3);
      width: 30%;

      &:hover {
        background-color: rgba(0,0,0,0.7);
      }
    }
  }

  @include media-query(md) {
    max-width: 50vw;
    margin-right: auto;
    margin-left: auto;
  }
}