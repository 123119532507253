.faq.page-title {
  margin-top: 30px;
}

@include media-query(md) {
  .faq.page-title {
    h1 {
      color: $black;
    }

    svg path {
      stroke: $black;
    }
  }

  .faq-search {
    display: none;
  }
}

.faq-qas {
  background-color: $white;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-radius: 16px;


  @include media-query(md) {
    max-width: 50vw;
  }

  h2 {
    font-size: 20px;
    padding: 20px 16px 10px;
  }

  .question-answer {
    position: relative;

    &:not(&.open):not(:last-child):after {
      content: "";
      left: 16px;
      position: absolute;
      right: 16px;
      border-bottom: 1px solid rgba($gray-dark, 0.15);
    }

    .question {
      position: relative;
      padding: 16px 40px 16px 16px;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        top: 20px;
        right: 20px;
        border: solid $orange;
        border-width: 0 2px 2px 0;
        transform: rotateZ(45deg);
        transition: transform 200ms ease-in-out;
      }

      &:hover {
        background-color: $gray-light;
      }
    }

    .answer {
      height: 0;
      overflow: hidden;
    }

    &.open {
      background-color: $gray-light;

      .question {
        padding-bottom: 15px;
        font-weight: 500;

        &:after {
          top: 24px;
        }

        &:after {
          transform: rotate3d(1, 0, 0, 180deg) rotate(45deg);
        }
      }

      .answer {
        height: initial;
        padding: 0 15px 15px;
      }
    }
  }

}