.button {
  font-size: 18px;
  font-weight: 400;
  padding: 7px 20px;
  border: unset;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &--primary {
    background-color: $color-primary;
    color: $white;

    &.has-icon-before,
    &.has-icon-after {
      svg path {
        fill: $white;
      }
    }

    &:hover {
      background-color: darken($color-primary, 10);
    }
  }

  &--secondary {
    background-color: transparent;
    color: $color-primary;
    border: 1px solid $color-primary;
    border-radius: 20px;

    &.has-icon-before,
    &.has-icon-after {
      svg path {
        fill: $color-primary;
      }
    }

    &:hover {
      background-color: lighten($color-primary, 60);
    }
  }

  &--dark {
    background-color: $color-dark;
    color: $white;
    border: 1px solid $color-dark;
    border-radius: 20px;

    &.has-icon-before,
    &.has-icon-after {
      svg path {
        fill: $white;
      }
    }

    &:hover {
      background-color: lighten($color-dark, 10);
    }
  }

  &--text {
    border: none;
    text-decoration: underline;
    text-decoration-color: #F89330;
    text-decoration-thickness: 2px;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: $white;
    font-weight: 400;
    font-size: 18px;

    &:hover {
      &:not([disabled]) {
        text-decoration: none;
      }
    }

    @include keyboard-focus {
      @extend :hover;
    }
  }

  &--md {
    @extend .text-sm;
    @extend .pl-sm;
    @extend .pr-sm;
    height: 32px;
    border-radius: 16px;
  }

  &--lg {
    @extend .text-md;
    @extend .pl-md;
    @extend .pr-md;
    height: 47px;
    border-radius: 23.5px;
  }

  &--fw {
    @extend .text-md;
    @extend .pl-md;
    @extend .pr-md;
    width: 100%;
    height: 47px;
    border-radius: 23.5px;
  }

  &--red {
    background-color: $red;
  }

  &[disabled] {
    background-color: $gray;
    color: initial;
    cursor: not-allowed;
  }

  .icon--before {
    @extend .mr-xs;
  }

  .icon--after {
    @extend .ml-xs;
  }

  &--icon {
    background-color: $orange;
    color: $white;
    border-radius: 50%;
    height: unset;
    padding: 10px;

    svg {
      margin-right: 0 !important;
    }
  }
}

.article-back-button,
.velg-header--actions {
  position: relative;
  z-index: 5;
  height: 80px;
  justify-content: flex-start;
  align-items: center;
  display: flex;

  a,
  button {
    text-decoration: none;
  }

  @include media-query(max-md) {
    width: calc(100vw - (30px * 2));
    background: $bg-gradient-dark;
  }

  svg {
    transform: rotateY(180deg);
    cursor: pointer;

    &:hover path {
      stroke: $orange;
    }
  }

  @include media-query(md) {
    span {
      color: $gray-dark;
    }

    svg path {
      stroke: $orange;
    }
  }
}