body {
    @extend .bg-dark-1;
    @extend .gradient-dark-1;

    @include media-query(md) {
        background: $gray-light;
    }
}

.wrapper {
    display: flex;
    justify-content: space-between;
}

.layout {
    position: relative;
    width: 100%;

    &__main {
        padding: 0 30px 30px;
    }

    @include media-query(md) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        background-color: $gray-light;
        min-height: 100vh;

        &__main {
            padding: 0 6vw 30px 30px;
        }

        &__aside {
            padding: 24px;
        }
    }

    @include media-query(lg) {
        grid-template-columns: 1fr 3fr;
    }
}

.footer-faded-logo {
    background-image: url('../../asset/logo-white.svg');
    position: fixed;
    bottom: 0;
    background-position-x: left;
    left: 0;
    background-position-y: bottom;
    width: 170vw;
    max-width: 1200px;
    height: 100vw;
    background-repeat: no-repeat;
    object-fit: cover;
    opacity: 0.1;
    transform: translate(-10vh, calc(0vh + 10vw));
    z-index: 0;
}

.page-title {
    h1 {
        display: inline-block;
        margin: 0;
        font-weight: 500;
    }

    @include media-query(max-md) {
        h1 {
            color: $white;
        }
    }

    svg {
        margin-right: 20px;
    }
}