.grid-0 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.grid-md {
    @extend .grid-0;
    grid-gap: map-get($space-scale, sm) map-get($space-scale, md);
}

.space-between {
    justify-content: space-between;
}
