.min-linje {
  margin-top: 30px;

  @include media-query(max-md) {
    color: white;
  }

  ol {
    margin-top: 35px;
    padding-left: 15px;

    li {
      margin-bottom: 20px;
      font-size: 18px;

      &::marker {
        color: #f06100;
      }
    }
  }

  .page-title h1 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
  }

  a {
    text-decoration: none;
  }

  button {
    color: $white;
    margin-top: 30px;

    span {
      text-decoration: none;
    }

    svg {
      margin-left: 20px !important;

      path {
        stroke: $white;
        fill: transparent !important;
      }
    }

    &:hover {
      color: $black;

      svg {
        path {
          stroke: $black;
        }
      }
    }
  }

  @include media-query(md) {
    max-width: 50vw;

    button {
      color: $gray-dark;
    }

    svg path,
    button svg path {
      stroke: $gray-dark;
    }
  }
}

.select-your-line {
  @include media-query(max-md) {
    margin-top: -80px;

    .page-header {
      color: $white;
    }

  }

  .page-header {
    margin-bottom: 30px;

    hr {
      margin: 16px 0;
      border: none;
      border-bottom: 1px solid rgba($gray, .15);
    }

    p {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      margin-bottom: 0;

      &+p {
        margin-top: 10px;
      }

      button {
        height: unset;
        padding: 0;

        svg {
          margin: 0;
        }
      }
    }

    @include media-query(md) {
      max-width: 50vw;
    }
  }

  .class-stage {
    font-size: 30px;
    font-weight: 500;
    line-height: 37px;
  }

  .stage-desc {
    font-size: 18px;
  }

  .more-info {
    &:hover {
      svg path {
        fill: adjust-hue($orange, 10deg);
      }
    }
  }

}

.line-summary {
  background-color: $white;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.03), 0px 11px 24px rgba(83, 83, 83, 0.29);
  border-radius: 16px;
  overflow: hidden;

  &--content {
    padding: 16px 16px 24px;
  }

  .section-color {
    height: 17px;
  }

  &.line--yrkeskompetanse {
    .section-color {
      background-color: $orange;
    }
  }

  &.line--studiekompetanse {
    .section-color {
      background-color: $green;
    }
  }

  .program-section {
    font-size: 14px;
    font-weight: 500;
    color: $gray;
    text-transform: uppercase;
    border-bottom: 1px solid rgba($gray-dark, .15);
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .section-desc {
    h2, h3 {
      color: $gray-dark;
      font-weight: 500;
    }

    h2 {
      font-size: 20px;
      line-height: 30px;
    }

    h3 {
      font-size: 16px;
    }
  }

  .percentage-presentration {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    grid-gap: 2px;

    .subject {
      padding: 10px 0;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: $white;

      &:nth-child(1) {
        background-color: $maroon;
        border-radius: 8px 0 0 8px;
      }

      &:nth-child(2) {
        background-color: $violet;
      }

      &:nth-child(3) {
        background-color: $dark-blue;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }
  }

  .subject-details {
    .detail {
      margin: 24px 0;

      .subject-title {
        font-size: 16px;
        font-weight: 500;
        color: $white;
        display: inline-block;
        padding: 5px 16px;
        border-radius: 8px;
        margin: 0;
      }

      &:nth-child(1) .subject-title {
        background-color: $maroon;
      }

      &:nth-child(2) .subject-title {
        background-color: $violet;
      }

      &:nth-child(3) .subject-title {
        background-color: $dark-blue;
      }
    }
  }

  @include media-query(md) {
    max-width: 50vw;
    margin-top: 45px;
  }
}

.velg-header {
  position: relative;

  @include media-query(max-md) {
    &:not(.is-wavy) {
      .velg-header--actions {
        background: $bg-gradient-dark;
      }

      #wavyBg {
        display: none;
      }
    }
  }

  @include media-query(md) {
    max-width: 50vw;

    #wavyBg {
      display: none;
    }
  }

  &--actions {
    justify-content: space-between;

    &.flex-end {
      justify-content: flex-end;
    }
  }

  &.is-wavy {
    margin-bottom: 40px;

    @include media-query(max-md) {
      padding-bottom: 50px;
    }

    @include media-query(max-md) {
      &:before {
        content: '';
        width: 100vw;
        background: #FFF;
        position: absolute;
        top: -10px;
        left: -30px;
        height: calc(100% - 100px);
        z-index: 4;
      }
    }

    .velg-header--actions {
      background: transparent;

      button:hover svg path {
        stroke: $orange;
      }

      svg path {
        stroke: $dark-blue;

      }
    }
  }



  .velg-summary {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    z-index: 5;

    @include media-query(md) {
      max-width: 50vw;
      border-bottom: 1px solid rgba($gray-dark, .3);
    }

    ul {
      margin-top: 0;
      padding-left: 30px;
      position: relative;

      li {
        margin-bottom: 16px;

        &:before {
          font-size: 2em;
          position: absolute;
          color: $green;
        }

        &.is-paabygg::before {
          color: $orange;
        }

        h3 {
          display: inline-block;
          font-size: 20px;
          line-height: 28px;
          font-weight: 500;
          margin: 0 0 10px;
        }

        p {
          font-size: 12px;
          font-weight: 500;
          color: $gray-dark;
          margin-bottom: 4px;
        }

        p:not(.line-sluttkompetanse) {
          text-transform: uppercase;
        }

        .school-name {
          display: flex;
          grid-gap: 6px;
          color: $dark-blue;
        }

        .line-sluttkompetanse {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .certification-type {
      text-align: left;
      padding-left: 30px;
    }
  }

  #wavyBg {
    position: absolute;
    right: 0;
    left: -30px;
    bottom: 0;
    z-index: 4;
    width: 100vw;

    path {
      fill: $white;
    }
  }

  &.is-summary {
    .summary-header {
      z-index: 5;
      position: relative;
      color: $gray-dark;
      margin-bottom: 30px;

      h1 {
        font-size: 30px;
        font-width: 500;
        margin-bottom: 16px;
      }

      p {
      }
    }

    .save-my-line-btn {
      color: $gray-dark;
      margin-bottom: 30px;

      .icon--before path {
        fill: transparent;
        stroke: $orange;
      }
    }
  }
}

.select-school,
.select-fylke,
.select-direction {
  @include media-query(md) {
    max-width: 50vw;
    margin-top: 45px;
  }

  @include media-query(max-md) {
    &--header {
      color: $white;
    }

  }
  &--header {
    margin-bottom: 30px;

    p {
      margin: 0;
    }

    h2 {
      margin-top: 10px;
    }

    h3 {
      font-size: 20px;
      line-height: 28px;
    }

    .schools-without-description {
      margin: 16px 0 24px;

      p {
        margin-bottom: 10px;
      }
    }
  }

  &--schools,
  &--fylker,
  &--directions {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;

    button,
    .school-labels {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 16px;
      text-align: left;
    }

    button {
      min-height: 64px;
      box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.03), 0px 11px 24px rgba(83, 83, 83, 0.29);
      border-radius: 16px;
      background-color: $white;
      padding: 16px;
      cursor: pointer;
      border: none;

      &:hover {
        background-color: #FAFAFA;

        .school-icon-after {
          animation: swing 200ms ease;
          animation-iteration-count: 1;
        }
      }
    }

    .school-link {
      line-height: 0.8;
      &:hover {
        path {
          fill: $red;
          filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.2));
        }
      }
    }

    .school-icon-after {
      svg path {
        stroke: $orange;
      }
    }

  }

  .schools-with-line {
    background-color: $orange;
    border-radius: 16px;
    padding: 12px 8px;

    p {
      padding: 0 10px;
      text-transform: uppercase;
      font-size: 14px;
    }

    button {
      width: 100%;
    }
  }

  .program-area {
    height: 100px;

    &--labels {
      height: 100%;
    }
  }

  .after-laerling {
    p {
      font-size: 18px;
    }
  }

  .direction-choices {
    padding: 0 20px;
    margin-bottom: 24px;
  }

}

.velg-total-summary,
.leaving-selection-page,
.line-saved {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;


  @include media-query(md) {
    max-width: 50vw;
  }

  p,
  h1 {
    text-align: center;
  }

  button {
    color: $gray-dark;
    cursor: pointer;

    &:hover {
      color: $orange;
    }
  }

  .modal & {
    p, h1, button:not(.save-line-btn) {
      color: $white;

      &:hover {
        color: $gray-dark;
      }
    }
  }


  @include media-query(max-md) {
    p,
    h1 {
      color: $white;
    }

    button {
      color: $white;

      &:hover {
        color: $gray-dark;
      }
    }
  }
}

.leaving-selection-page {
  p {
    font-size: 20px;
  }

  .save-line-btn {
    background-color: $white;
    color: $gray-dark;

    svg path {
      stroke: $orange;
      fill: transparent;
    }
  }
}

.line-saved {
  h1 {
    font-size: 30px;
    font-weight: 500;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;

    path {
      stroke: $orange;
    }
  }
}

.full-screen-modal {
  background: $bg-gradient-dark;
}